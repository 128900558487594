<template>
  <v-container>
    <div id="nav">
      <Nav/>
    </div>
    <v-row no-gutters>
      <v-col/>
      <v-col cols="8">
        <div align="center">
          <h1 class="v-heading ">{{ message.name }}</h1>
          <p>
          <p class="mx-auto">{{ message.comment }}</p>
          <v-btn dark class="mx-auto" :href="message.url">下载地址</v-btn>
        </div>
        <div v-for="item in message.content" :key="item">
          <h2>{{ item.name }}</h2>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  文件名称
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="l in item.list"
                  :key="l"
              >
                <td>{{ l }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
      <v-col/>
    </v-row>
  </v-container>
</template>

<script>
import Nav from "@/components/Nav";

export default {
  name: "bios",
  components: {
    Nav
  },
  data: function () {
    return {
      message: [],
    }
  },
  methods: {
    fetchData() {
      this.axios
          //.get('../assets/data/' + this.$route.params.table + '.json')
          .get('https://data.yang2000ling.com/data/bios.json')
          .then(response => (this.message = response.data))
          .catch(error => console.log(error))
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>